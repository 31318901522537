import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import BestPostageMetersWrapper from "@components/layouts/bestpostagemetersWrapper";
import MainBanner from "@components/bestpostagemeters/mainBanner";

import { useDomainContext } from "@hooks/useDomainContext";

const FAQ = dynamic(() => import("@components/bestpostagemeters/faq"));
const OffersList = dynamic(() => import("@components/shared/offersList"));

export default function BestPostageMetersHome(): ReactElement {
    const { domain } = useDomainContext();
    const style = {
        background: "#fff",
        borderColor: "#e3e3e3",
        buttonBackground: "#3ea636",
        buttonForground: "#fff",
    };

    return (
        <BestPostageMetersWrapper>
            <MainBanner />
            <section style={{ padding: "0 15px" }}>
                <OffersList offers={domain?.defaultOffers} style={style} />
            </section>
            <FAQ />
        </BestPostageMetersWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
